<template>
  <div class="">
    <div class="Ptit">
      <strong class="tit">消息通知</strong>
    </div>
    <div>
      <div class="">
        <!-- {{ mList }} -->
        <el-collapse
          class="collapsWhg"
          v-model="activeNames"
          @change="handleChange"
        >
          <el-collapse-item
            v-for="(itm, itx) in mList"
            :key="itx"
            :name="itm.peId"
          >
            <template #title>
              <div class="msgCollapse">
                <div class="tit">{{ itm.title }}</div>
                <span class="rtime">{{ itm.insertDatetime }}</span>
              </div>
            </template>
            <div class="stext">
              {{ itm.content }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="paginationPage"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { getUsMessageList } from '@/api/user'
import { sign } from '@/utils/storage'
// import tools from '@/utils/tools'
// import { useRouter } from 'vue-router'
// const router = useRouter()

const activeNames = ref([])
const mList = ref([])
const total = ref(0)
const pages = ref({
  page: 1,
  size: 10
})

const getPage = async () => {
  var res = await getUsMessageList(sign(pages.value))
  mList.value = res.list
  total.value = res.total
  delete pages.value.sign
}
getPage()

const paginationPage = v => {
  pages.value.page = v
  getData()
}

const handleChange = v => {
  console.log(v)
}

// const goPage = ss => {
//   router.push(ss)
// }
</script>
<style lang="scss" scoped>
.msgCollapse {
  width: 100%;
  display: flex;
  .tit {
    flex: 1;
    font-size: 16px;
  }
  .rtime {
    color: #888;
    font-size: 12px;
  }
}
</style>
